import PropTypes from 'prop-types';

function SubmitButton(props) {
  const { value, disabled = false, dataTestId = 'user-submit' } = props;
  return (
    <input
      type="submit"
      value={value}
      disabled={disabled}
      className="h-38 w-full font-Roboto font-medium rounded-[40px] text-regular leading-regular cursor-pointer outline-none bg-primary-700 text-neutral-0"
      data-testid={dataTestId}
    />
  );
}

SubmitButton.propTypes = {
  value: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SubmitButton;
