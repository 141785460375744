import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { setCookie } from '@/libs/cookie';

import { Text } from '@/components/Index';

import { LOGIN_REDIRECTION_URLS, APP_STAGE } from '@/configs/index';
import { DEVELOPMENT } from '@/configs/constants';

import LiftoffIcon from '../public/images/liftoff-icon.svg';

function SuccessLogin(props) {
  const { access_sites, token, username, originalUrl } = props.results;
  const router = useRouter();

  const setCookiesCallback = useCallback(() => {
    setCookie('username', username);
    setCookie('token', token);
  }, [token, username]);

  function getRedirectUrlByRole(role = '') {
    if (!LOGIN_REDIRECTION_URLS[role]) {
      return '';
    }

    let queryToken = '';
    if (APP_STAGE === DEVELOPMENT) {
      queryToken = `?token=${token}&username=${username}`;
    }

    return `${LOGIN_REDIRECTION_URLS[role]}${queryToken}`;
  }

  function getRedirectUrlByCallback(callback = '') {
    let queryToken = '';
    if (APP_STAGE === DEVELOPMENT) {
      queryToken = `token=${token}&username=${username}`;
    }

    let cb = callback;
    if (cb) {
      // create a url object from callback after trimming its last /
      cb = callback.replace(/\/$/, '');
      const url = new URL(callback);
      if (url.pathname === '/') {
        cb += '/auth';
        cb += queryToken ? `?${queryToken}` : '';
      } else {
        cb = `${url.origin}/auth?redirect=${url.pathname}${url.search}`;
        cb += queryToken ? `&${queryToken}` : '';
      }
    }

    return cb;
  }

  function redirectUrl(url = '') {
    if (url) {
      router.push(url);
    }
  }

  function handleRedirectionClick(role = '') {
    redirectUrl(getRedirectUrlByRole(role));
  }

  useEffect(() => {
    setCookiesCallback();

    if (originalUrl) {
      // automatically redirect if originalUrl is set
      redirectUrl(getRedirectUrlByCallback(originalUrl));
    } else if (Array.isArray(access_sites) && access_sites.length === 1) {
      // automatically redirect if only one access_sites value available
      redirectUrl(getRedirectUrlByRole(access_sites[0]));
    }
  });

  if (originalUrl) {
    // redirect url without showing any content
    return <></>;
  } else {
    return (
      <>
        <div className="flex h-full w-full" data-testid="success-login">
          <section className="w-full">
            <div className="flex flex-col w-full justify-center">
              {!Array.isArray(access_sites) || access_sites.length < 1 ? (
                ''
              ) : (
                <div className="flex justify-center w-full">
                  <div className="sm:hidden max-w-xs my-4 p-8 border-solid rounded-md bg-neutral-0">
                    <p>
                      For a better user experience please use your desktop web
                      browser.
                    </p>
                    <p>Thank you.</p>
                  </div>
                  <div className="hidden sm:block sm:w-4/5 md:w-2/3 xl:w-2/3">
                    <p className="block text-center text-neutral-1000">
                      Welcome back! Select a platform.
                    </p>
                    <div className="flex flex-col w-full mt-64">
                      <table className="table-fixed">
                        <thead>
                          <tr>
                            <th className="text-regular font-medium text-neutral-700 pl-16 pb-8 w-[80px] text-left">
                              <p>Platform</p>
                            </th>
                            <th>&nbsp;</th>
                            <th className="text-regular font-medium text-neutral-700 pb-8 w-2/3 text-left">
                              Description
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {access_sites.includes('demand_self') &&
                            LOGIN_REDIRECTION_URLS['demand_self'] && (
                              <>
                                <tr
                                  className="bg-neutral-0 cursor-pointer"
                                  data-testid="advertiser-selfserve-redirect"
                                  onClick={() => {
                                    handleRedirectionClick('demand_self');
                                  }}
                                >
                                  <td className="rounded-l-lg border-t border-b border-l border-neutral-300 py-8 px-16">
                                    <div className="p-8 text-center">
                                      <LiftoffIcon
                                        width={20}
                                        height={32}
                                        fill="#273143"
                                        className="inline-block"
                                      />
                                    </div>
                                  </td>
                                  <td className="border-t border-b border-neutral-300">
                                    <span className="font-medium text-neutral-1000">
                                      Direct
                                    </span>
                                  </td>
                                  <td className="border-t border-b border-r border-neutral-300 py-3 px-1">
                                    <Text>
                                      Grow your audience through ad campaigns.
                                    </Text>
                                  </td>
                                </tr>
                              </>
                            )}
                          {access_sites.includes('supply_self') &&
                            LOGIN_REDIRECTION_URLS['supply_self'] && (
                              <>
                                <tr
                                  className="bg-neutral-0 cursor-pointer"
                                  data-testid="publisher-selfserve-redirect"
                                  onClick={() => {
                                    handleRedirectionClick('supply_self');
                                  }}
                                >
                                  <td className="rounded-l-lg border-t border-b border-l border-neutral-300 py-8 px-16">
                                    <div className="p-8 text-center">
                                      <LiftoffIcon
                                        width={20}
                                        height={32}
                                        fill="#273143"
                                        className="inline-block"
                                      />
                                    </div>
                                  </td>
                                  <td className="border-t border-b border-neutral-300">
                                    <span className="font-medium text-neutral-1000">
                                      Monetize
                                    </span>
                                  </td>
                                  <td className="border-t border-b border-r border-neutral-300 py-3 px-1">
                                    <Text>
                                      Grow your business through app revenue.
                                    </Text>
                                  </td>
                                </tr>
                              </>
                            )}
                          {access_sites.includes('demand_ctrl') &&
                            LOGIN_REDIRECTION_URLS['demand_ctrl'] && (
                              <>
                                <tr
                                  className="bg-neutral-0 cursor-pointer boder border-neutral-300"
                                  data-testid="advertiser-admin-redirect"
                                  onClick={() => {
                                    handleRedirectionClick('demand_ctrl');
                                  }}
                                >
                                  <td className="rounded-l-lg border-t border-b border-l border-neutral-300 py-8 px-16">
                                    <div className="p-8 text-center bg-primary-700 rounded-full">
                                      <LiftoffIcon
                                        width={20}
                                        height={32}
                                        fill="#ffffff"
                                        className="inline-block"
                                      />
                                    </div>
                                  </td>
                                  <td className="border-t border-b border-neutral-300">
                                    <span className="font-medium text-neutral-1000">
                                      Direct Admin
                                    </span>
                                  </td>
                                  <td className="border-t border-b border-r border-neutral-300 py-3 px-1">
                                    <Text>
                                      Admin UI for internal users to manage
                                      Direct accounts and network settings.
                                    </Text>
                                  </td>
                                </tr>
                              </>
                            )}
                          {access_sites.includes('supply_ctrl') &&
                            LOGIN_REDIRECTION_URLS['supply_ctrl'] && (
                              <>
                                <tr
                                  className="bg-neutral-0 cursor-pointer"
                                  data-testid="advertiser-admin-redirect"
                                  onClick={() => {
                                    handleRedirectionClick('supply_ctrl');
                                  }}
                                >
                                  <td className="rounded-l-lg border-t border-b border-l border-neutral-300 py-8 px-16">
                                    <div className="p-8 text-center bg-primary-700 rounded-full">
                                      <LiftoffIcon
                                        width={20}
                                        height={32}
                                        fill="#ffffff"
                                        className="inline-block"
                                      />
                                    </div>
                                  </td>
                                  <td className="border-t border-b border-neutral-300">
                                    <span className="font-medium text-neutral-1000">
                                      Monetize Admin
                                    </span>
                                  </td>
                                  <td className="border-t border-b border-r border-neutral-300 py-3 px-1">
                                    <Text>
                                      Admin UI for internal users to manage
                                      Monetize accounts and network settings.
                                    </Text>
                                  </td>
                                </tr>
                              </>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </>
    );
  }
}

SuccessLogin.propTypes = {
  results: PropTypes.shape({
    access_sites: PropTypes.array,
    token: PropTypes.string,
    username: PropTypes.string,
    originalUrl: PropTypes.string,
  }),
};

SuccessLogin.defaultProps = {
  results: {
    access_sites: [],
    token: '',
    username: '',
    originalUrl: '',
  },
};

export default SuccessLogin;
