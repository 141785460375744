import Head from 'next/head';
import PropTypes from 'prop-types';
import { SITE_TITLE } from '@/configs/index';

function PageHead({ title, description }) {
  return (
    <Head>
      <title>
        {title} - {SITE_TITLE}
      </title>
      <meta name="description" content={description} key="description" />
    </Head>
  );
}

PageHead.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default PageHead;
