import PropTypes from 'prop-types';

function Warning(props) {
  const outputMessages = Array.isArray(props.messages)
    ? props.messages
    : [props.messages];
  const messageItems = outputMessages.map((msg, index) => {
    if (msg) {
      return (
        <li
          key={index}
          className="flex bg-error-100 border border-error-900 p-8 mb-4 rounded"
        >
          <span className="material-icons text-medium text-neutral-1000">
            error_outline
          </span>
          <span className="inline-block ml-8 text-regular text-neutral-1000 leading-paragraph align-middle capitalize-first">
            {msg.toString()}
          </span>
        </li>
      );
    }
  });
  return (
    <>
      <div role="alert" data-testid="warning-message">
        <ul>{messageItems}</ul>
      </div>
    </>
  );
}

Warning.propTypes = {
  messages: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Warning;
