import PropTypes from 'prop-types';

function FormDescription(props) {
  return (
    <p className="font-Roboto text-neutral-900 text-regular leading-paragraph">
      {props.content}
    </p>
  );
}

FormDescription.propTypes = {
  content: PropTypes.string.isRequired,
};

export default FormDescription;
