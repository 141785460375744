import request from './request';
import { DEVELOPMENT } from '@/configs/constants';
import { APP_STAGE } from '@/configs/index';

export const formatResponseErr = ({ code = 400, messages = [''] }) => {
  return `${messages[0]} Error Code: ${code}.`;
};

export const isValidEmail = (v) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v)

export const generateResponseErr = (
  { code, message } = {
    code: 400,
    message: [],
  }
) => {
  return {
    code,
    messages: [message],
  };
};

export const filterOriginUrl = (urlStr) => {
  if (!urlStr || typeof urlStr !== 'string' || urlStr.length < 9) {
    return '';
  }

  let re;
  if (APP_STAGE === DEVELOPMENT) {
    re = new RegExp(
      '(http|https)?://(www.)?[-a-zA-Z0-9@:%._+~#=]{0,256}.?(vungle|localhost).(io|com|)/?([-a-zA-Z0-9()@:%_+.~#?&//=]*)'
    );
  } else {
    // match urls which end with vungle.com or vungle.io
    re = new RegExp(
      'https?://(www.)?[-a-zA-Z0-9@:%._+~#=]{0,256}.?vungle.(io|com)/?([-a-zA-Z0-9()@:%_+.~#?&//=]*)'
    );
  }

  return re.test(urlStr) ? urlStr : '';
};

export { request };
