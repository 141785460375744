import PropTypes from 'prop-types';

function ShowError({ err }) {
  const errMessage = Array.isArray(err.messages)
    ? err.messages[0]
    : 'An unknown error occurred.';

  return (
    <div className="flex" data-testid="response-error">
      <div className="flex border border-error-900 w-full p-8 rounded-md bg-error-100">
        <span className="material-icons inline-block text-medium text-neutral-1000">
          error_outline
        </span>
        <span className="inline-block ml-8 text-regular text-neutral-1000 leading-paragraph align-middle capitalize-first">
          {errMessage}
        </span>
      </div>
    </div>
  );
}

ShowError.propTypes = {
  err: PropTypes.shape({
    code: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    messages: PropTypes.array,
  }),
};

export default ShowError;
