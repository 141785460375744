import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

const PolicyBanner = dynamic(() => import('./PolicyBanner'), { ssr: false });
const Logo = dynamic(() => import('@/components/LogoImage'), {
  ssr: false,
});

function Layout({ children }) {
  return (
    <>
      <script
        type="text/javascript"
        async
        defer
        src="https://cdn.bizible.com/scripts/bizible.js"
      ></script>
      <noscript>Javascript is needed to run the app.</noscript>
      <div className="relative block h-screen min-h-screen bg-neutral-100">
        <header className="sticky z-50 top-[0px]" aria-label="main logo">
          <Logo />
          <div className="w-full h-16 bg-gradient-to-b from-neutral-100 to-transparent"></div>
        </header>
        <main
          className="relative block w-full overflow-hidden"
          aria-label="main body"
        >
          <div className="flex justify-center">{children}</div>
        </main>
        <PolicyBanner />
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
