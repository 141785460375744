import PropTypes from 'prop-types';

function Heading(props) {
  return (
    <h1 className="font-Poppins font-semibold text-large text-neutral-1000 leading-regular mb-8">
      {props.text}
    </h1>
  );
}

Heading.propTypes = {
  text: PropTypes.string,
};

export default Heading;
