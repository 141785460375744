import Image from 'next/image';
import Link from 'next/link';

function LogoImage() {
  return (
    <div
      className="flex justify-center pt-64 pb-16 bg-neutral-100"
      data-testid="logo-image"
    >
      <Link href="/login">
        <a>
          <Image
            src="/images/liftoff-logo.svg"
            alt="Liftoff Logo"
            width={100}
            height={36}
          />
        </a>
      </Link>
    </div>
  );
}

export default LogoImage;
