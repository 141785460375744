import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Warning from '@/components/Warning';

function FormInput(props) {
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  function handleOnFocus(e) {
    setIsFocused(true);
    e.preventDefault();
  }

  function handleOnBlur(e) {
    setIsFocused(false);
    e.preventDefault();
  }

  function handleOnChange(e) {
    e.preventDefault();
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  }

  function handleOnClear(e) {
    e.preventDefault();
    setIsFocused(true);
    if (props.onChange) {
      props.onChange('');
    }
  }

  function handleOnMouseEnter(e) {
    e.preventDefault();
    setIsHovered(true);
  }

  function handleOnMouseLeave(e) {
    e.preventDefault();
    setIsHovered(false);
  }

  return (
    <>
      <div className="block relative">
        <div
          className="block"
          onFocus={(e) => handleOnFocus(e)}
          onBlur={(e) => handleOnBlur(e)}
          onMouseEnter={(e) => handleOnMouseEnter(e)}
          onMouseLeave={(e) => handleOnMouseLeave(e)}
        >
          <input
            id={props.id}
            name={props.name}
            type={props.type}
            defaultValue={props.defaultValue}
            disabled={props.isDisabled}
            required={props.isRequired}
            placeholder={props.placeholder}
            className={classNames(
              'w-full h-38 px-8 py-12 border rounded text-regular text-neutral-1000 placeholder-neutral-500',
              { 'bg-neutral-100': props.isDisabled },
              props.isDisabled
                ? 'border-neutral-300'
                : props.isErrored
                ? 'border-error-900'
                : isFocused || isHovered
                ? 'border-primary-700'
                : 'border-neutral-500'
            )}
            value={props.value}
            data-testid={props.dataTestId}
            onChange={(e) => handleOnChange(e)}
          />
          {(props.isErrored || isFocused || isHovered) &&
            props.value &&
            !props.isDisabled && (
              <span
                className="material-icons text-regular text-neutral-500 border-0 shadow-none hover:text-primary-700 hover:cursor-pointer absolute top-[12px] right-[8px]"
                role="button"
                tabIndex={-1}
                onClick={(e) => handleOnClear(e)}
                onKeyDown={() => {}}
              >
                close
              </span>
            )}
        </div>

        {props.isErrored && props.errorMessages && (
          <div className="mt-8">
            <Warning messages={props.errorMessages} />
          </div>
        )}
      </div>
    </>
  );
}

FormInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isErrored: PropTypes.bool,
  errorMessages: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  dataTestId: PropTypes.string,
};

FormInput.defaultProps = {
  id: 'user-input',
  name: 'user-input',
  type: 'text',
  placeholder: '',
  value: '',
  isDisabled: false,
  isRequired: false,
  isErrored: false,
  dataTestId: 'user-input',
};

export default FormInput;
