import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Select, { components } from 'react-select';
import Warning from '@/components/Warning';

function FormSelect(props) {
  const [isHovered, setIsHovered] = useState(false);

  function handleOnMouseEnter(e) {
    e.preventDefault();
    setIsHovered(true);
  }

  function handleOnMouseLeave(e) {
    e.preventDefault();
    setIsHovered(false);
  }

  const customSelectStyles = {
    // ref: https://react-select.com/styles
    control: (provided, state) => {
      return {
        ...provided,
        borderColor: state.menuIsOpen ? '#555fff' : '#9AA9C3',
        boxShadow: 'none',
        borderBottomLeftRadius: state.menuIsOpen ? '0' : '',
        borderBottomRightRadius: state.menuIsOpen ? '0' : '',
        '&:hover': {
          border: '1px solid #555fff',
        },
        '&:focus': {
          border: '1px solid #555fff',
        },
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        border: '1px solid #555fff',
        borderTop: '0',
        marginTop: '0',
      };
    },
    option: (provided, state) => ({
      ...provided,
      color: '#273143',
      backgroundColor: state.isSelected
        ? '#f0f4ff'
        : state.isFocused
        ? '#dce6ff'
        : '#ffffff',
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#9AA9C3',
      };
    },
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <span
          className={classNames(
            'material-icons text-regular',
            isHovered ? 'text-primary-700' : 'text-neutral-500'
          )}
        >
          expand_more
        </span>
      </components.DropdownIndicator>
    );
  };

  return (
    <>
      <div
        className="block"
        data-testid={props.dataTestId}
        onMouseEnter={(e) => handleOnMouseEnter(e)}
        onMouseLeave={(e) => handleOnMouseLeave(e)}
      >
        <Select
          inputId={props.id}
          name={props.name}
          components={{ DropdownIndicator }}
          instanceId={props.id}
          placeholder={props.placeholder}
          options={props.options}
          onChange={props.onChange}
          styles={customSelectStyles}
          defaultValue={props.defaultValue}
          value={props.options?.find((c) => c.value === props.value)}
        />
        {props.isErrored && props.errorMessages && (
          <div className="mt-8">
            <Warning messages={props.errorMessages} />
          </div>
        )}
      </div>
    </>
  );
}

FormSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  isErrored: PropTypes.bool,
  errorMessages: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  dataTestId: PropTypes.string,
};

FormSelect.defaultProps = {
  id: 'user-select',
  name: 'user-select',
  placeholder: '',
  value: '',
  isErrored: false,
  errorMessages: '',
  dataTestId: 'user-select',
};

export default FormSelect;
