import Cookies from 'js-cookie';
import { APP_STAGE } from '@/configs/index';
import { PRODUCTION } from '@/configs/constants';

function removeCookie(key) {
  const cookiesDomain = APP_STAGE === PRODUCTION ? '.vungle.com' : '.vungle.io';
  return Cookies.remove(key, { domain: cookiesDomain });
}

function setCookie(key, value) {
  const cookiesDomain = APP_STAGE === PRODUCTION ? '.vungle.com' : '.vungle.io';
  return Cookies.set(key, value, { domain: cookiesDomain });
}

export { removeCookie, setCookie };
