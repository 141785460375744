import PropTypes from 'prop-types';

function Text({ children, dataTestId }) {
  return (
    <p
      className="text-neutral-900 text-regular leading-paragraph"
      data-testid={dataTestId}
    >
      {children}
    </p>
  );
}

Text.propTypes = {
  children: PropTypes.node,
  dataTestId: PropTypes.string,
};

Text.defaultProps = {
  dataTestId: 'components-text',
};

export default Text;
