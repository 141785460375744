import React, { useState, useEffect } from 'react';

export default function PolicyBanner() {
  const [showPolicyBanner, setShowPolicyBanner] = useState(false);

  useEffect(() => {
    const isPolicyAccepted =
      localStorage.getItem('is_Policy_Accepted') === 'true' ? true : false;
    setShowPolicyBanner(!isPolicyAccepted);
  }, [showPolicyBanner]);

  function handleBannerClose() {
    setShowPolicyBanner(false);
    localStorage.setItem('is_Policy_Accepted', 'true');
  }

  return (
    <>
      {showPolicyBanner && (
        /* z-index shall be above 10 (which is recaptcha default z-index)*/
        <div
          className="fixed bottom-[0px] z-20 w-full"
          data-testid="policy-section"
          role="banner"
          aria-label="policy banner"
        >
          <div className="flex justify-between items-center bg-neutral-0 border-t border-neutral-300 text-neutral-1000 text-regular py-16 px-32">
            <p>
              The site uses cookies.{' '}
              <a
                href="https://vungle.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Read more
              </a>
            </p>
            <div className="block">
              <button
                data-testid="close-button"
                aria-label="Close Banner"
                title="Close Banner"
                className="pt-4"
                onClick={() => {
                  handleBannerClose();
                }}
              >
                <span className="material-icons icon-button">close</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
