import PropTypes from 'prop-types';

function Card({ children, dataTestId, className = 'w-[466px]', ...props }) {
  return (
    <section
      className={`block bg-neutral-0 border border-neutral-300 p-32 mb-[72px] rounded ${className}`}
      data-testid={dataTestId}
      {...props}
    >
      {children}
    </section>
  );
}

Card.propTypes = {
  children: PropTypes.node,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

Card.defaultProps = {
  dataTestId: 'components-card',
};

export default Card;
