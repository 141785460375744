import PropTypes from 'prop-types';

function Body({ children, dataTestId }) {
  return (
    <div className="flex h-full w-full justify-center" data-testid={dataTestId}>
      {children}
    </div>
  );
}

Body.propTypes = {
  children: PropTypes.node,
  dataTestId: PropTypes.string,
};

Body.defaultProps = {
  dataTestId: 'components-body',
};

export default Body;
